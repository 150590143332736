<template lang="pug">
header.s-header.s-swatch-black(:class='{inverted}')
  .s-headerBar
    .s-wrapper
      .s-relative.s-flex-items--center
        a.s-headerBurger.s-padding--xs.s-text-baseline--single-size--xxl(href='javascript:void(0)' @click='toggleBurger')
          span.icon-menu-fill.s-headerBurgerOpen.s-swatch-pink--text
          span.icon-close-fill.s-headerBurgerClose.s-swatch-pink--text
        a.s-headerLogo.s-padding-y--s.s-margin-right--l(href='#intro' v-smooth-scroll)
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path class="st0" d="M14.5 22.1H7.9c-.8 0-1.4-.1-1.8-.3-.4-.2-.6-.8-.6-1.7v-1.5c0-.8.1-1.4.4-1.7.3-.3.9-.5 1.9-.5h11.9v-3.7c-1-.4-2.8-.6-5.4-.8-2.7-.2-5.2-.3-7.7-.3-4.4 0-6.6 2.2-6.6 6.6v2.2c0 2.4.6 4.1 1.7 5.1S4.6 27 6.9 27h6.5c.8 0 1.3.2 1.6.5.3.3.5.8.5 1.5v1.6c0 1.3-.7 2-2.1 2H1.1v3.6c.8.4 2.7.6 5.7.9s5.7.3 8.1.3c4.1 0 6.2-2.2 6.2-6.5v-2.5c0-1.9-.5-3.5-1.6-4.6-1.2-1.1-2.8-1.7-5-1.7zM38.2 11.6c-1.4 0-2.9.3-4.6 1-1.6.6-2.9 1.3-3.8 2L28.7 12h-4.4v38.3h5.5V35.9c1.2.5 2.6.9 4.2 1.2 1.6.3 3 .5 4.1.5 2.6 0 4.6-.9 6-2.6 1.6-2 2.3-5.5 2.3-10.6.1-8.5-2.7-12.8-8.2-12.8zm1 20.8c-.5.4-1.2.6-2.1.6-2.6 0-5-.4-7.2-1.1V18.3c2.3-1.2 4.7-1.8 7.2-1.8 1.3 0 2.2.6 2.9 1.9.6 1.3 1 3.3 1 6.2 0 4.3-.6 6.9-1.8 7.8zM71.2 20.8c0-2.8-.4-4.9-1.2-6.1-.4-.7-.9-1.2-1.4-1.6s-1.2-.7-2.1-.9c-.9-.2-1.7-.3-2.6-.4-.8-.1-2.2-.1-4-.1-1.8 0-3.4.2-4.9.6-1.4.4-2.6 1.3-3.6 2.5-1.2 1.7-1.9 4.9-1.9 9.7 0 4.8.6 8.2 1.8 10.1 1.2 1.9 3.4 2.9 6.6 2.9 5.8 0 10-.4 12.6-1.3v-3.9c-2.2.4-4.7.5-7.6.5s-4.5-.1-5.1-.2-1.1-.4-1.6-.8c-.8-.7-1.2-2.3-1.2-4.8h9c4.9 0 7.2-2.1 7.2-6.2zm-15.9 1.9c0-1.1 0-2.1.1-3 .1-1 .5-1.8 1.2-2.6.5-.6 2-.9 4.5-.9 2 0 3.2.3 3.8.8.5.5.8 1.7.8 3.6 0 1.4-.8 2.1-2.4 2.1h-8zM80.6 31.3c-.4-.7-.6-1.9-.6-3.7V-.2h-5.5v28.4c0 3.2.6 5.5 1.7 6.9 1.1 1.4 2.7 2.1 4.8 2.1h5v-3.5c-.2 0-1.4-.3-3.6-1-.8-.2-1.5-.7-1.8-1.4zM96.4 32.7c-.8-.3-1.4-.7-1.8-1.4-.4-.7-.6-1.9-.6-3.7V-.2h-5.5v28.4c0 3.2.6 5.5 1.7 6.9 1.1 1.4 2.7 2.1 4.8 2.1h5.1v-3.5c-.3 0-1.5-.3-3.7-1z"/></svg>
        #tinyWatch.watch.s-flex-items--center.s-items-spacing--xs.s-text--nowrap(@click="showModalVideo(true)")
          .thumbnail.s-relative.s-background--cover
            .icon-play-circle-line.s-position--center.s-text--bold-size--xxl
          .s-expand.s-hide-tinyphone
            .s-text-size--3xs--caps.s-swatch-pink--text check out
            .s-text--bold-size--s Platform Promo
        .s-headerInner.s-flex-items--center-content--end.s-expand
          nav.navMain.s-text-size--xs--semibold--caps--nowrap.s-items.s-width--expand.s-flex-items--center-content--end.s-expand-tinyPhone()
            a.navTrigger.s-hide(@click='hideMobileNav' data-nav="intro" href='#intro') Intro
            a.navTrigger(@click='hideMobileNav' data-nav="platform" href='#platform' v-smooth-scroll) Overview
            .navDropdown
              a#ourspell.navTrigger(@click='hideMobileNav' data-nav="products" href='#products' v-smooth-scroll)
                | Spell
                span.icon-arrow-down-s-fill.s-margin-left--xxs
              nav.s-box.s-padding--none.s-popover--topCenter.s-position--bottomCenter--pushed.s-shadow--xl
                .s-popoverArrow
                .s-flex-direction--column-content--start
                  a.s-padding-y--xxs.s-padding-x--s.s-flex-items--center(@click='hideMobileNav' data-nav="account" href='#account' v-smooth-scroll)
                    span.icon-pie-chart-2-line.s-swatch-primary--text.s-margin-right--xs.s-text-size--m
                    span Account
                  a.s-padding-y--xxs.s-padding-x--s.s-flex-items--center(@click='hideMobileNav' data-nav="ecommerce" href='#ecommerce' v-smooth-scroll)
                    span.icon-shopping-basket-2-line.s-swatch-primary--text.s-margin-right--xs.s-text-size--m
                    span E-commerce
                  a.s-padding-y--xxs.s-padding-x--s.s-flex-items--center(@click='hideMobileNav' data-nav="billing" href='#billing' v-smooth-scroll)
                    span.icon-file-list-3-line.s-swatch-primary--text.s-margin-right--xs.s-text-size--m
                    span Billing
                  a.s-padding-y--xxs.s-padding-x--s.s-flex-items--center(@click='hideMobileNav' data-nav="links" href='#links' v-smooth-scroll)
                    span.icon-links-line.s-swatch-primary--text.s-margin-right--xs.s-text-size--m
                    span Links
                  a.s-padding-y--xxs.s-padding-x--s.s-flex-items--center(@click='hideMobileNav' data-nav="feed" href='#feed' v-smooth-scroll)
                    span.icon-list-check.s-swatch-primary--text.s-margin-right--xs.s-text-size--m
                    span Feed &amp; More
            a.navTrigger(@click='hideMobileNav' data-nav="wizard" href='#wizard' v-smooth-scroll) Wizard
            a.navTrigger(@click='hideMobileNav' data-nav="cases" href='#cases' v-smooth-scroll) Case Studies
          nav.navCTA.s-text--nowrap.s-margin-left
            router-link.s-button-spacing--s.s-blockWidth--9.s-text-size--s.s-swatch-primary.s-shape-pill.s-shadow--s(data-nav="final" to='/book')
              | Book a Demo
            a.s-button-spacing--s.s-border--outline.s-swatch-primary.s-shape-pill.s-show-mobile.s-hide-tinyphone(href='/deck')
              .s-swatch-default--text
                | Download Deck
                span.icon-download-line.s-margin-left--xs.s-text--semibold
</template>
<script>
import router from '@/router'
export default {
  props: {
    inverted: { type: Boolean }
  },
  mounted () {
    // New nav
    const sections = [...document.querySelectorAll('.navSection')]
    console.log(sections)
    const getLinkById = (id) => document.querySelector(`a[data-nav='${id}']`)
    const inView = (section) => {
      let top = section.offsetTop
      const height = section.offsetHeight
      const header = document.querySelector('.s-header').offsetHeight

      while (section.offsetParent) {
        section = section.offsetParent
        top += section.offsetTop - header
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        top + height > window.pageYOffset
      )
    }

    function navInit () {
      let next = false
      sections.forEach((section) => {
        const a = getLinkById(section.id)

        if (inView(section) && !next) {
          if (!a.classList.contains('current')) {
            a.classList.add('current')
            section.classList.add('animate')
            if ((section.id !== 'intro') && (section.id !== 'final')) {
              router.push({ name: router.name, hash: `#${section.id}` })
              if (['account', 'billing', 'ecommerce', 'links', 'feed'].includes(section.id)) {
                document.querySelector('#ourspell').classList.add('current')
              } else {
                document.querySelector('#ourspell').classList.remove('current')
              }
            } else {
              router.push({ name: router.name, hash: '' })
            }
          }
          next = true
        } else {
          a.classList.remove('current')
          section.classList.remove('animate')
        }
      })
    }
    window.onload = () => {
      navInit()
    }

    // Header
    const header = document.querySelector('.s-header')

    window.addEventListener('scroll', function () {
      // Floater
      if (window.pageYOffset > 20 && window.innerWidth > 1020) {
        header.classList.add('floating')
      } else if (window.pageYOffset > 0 && window.innerWidth < 1020) { // mobile top margin
        header.classList.add('floating')
      } else {
        header.classList.remove('floating')
      }

      // Tiny Watch
      const a = document.getElementById('platform').offsetTop
      const n = document.getElementById('tinyWatch')
      a < window.pageYOffset + 500 ? n.classList.add('visible') : n.classList.remove('visible')

      // Nav
      navInit()
    })

    window.addEventListener('resize', function () {
      if (window.innerWidth > 1020) {
        header.classList.remove('navMobileVisible')
      }
    })
  },

  methods: {
    hideMobileNav () {
      document.querySelector('.s-header').classList.remove('navMobileVisible')
      document.querySelector('body').classList.remove('s-overflow--hidden')
    },
    toggleBurger () {
      document.querySelector('.s-header').classList.toggle('navMobileVisible')
      document.querySelector('body').classList.toggle('s-overflow--hidden')
    }
  },

  watch: {
    $route (to, from) {
      document.querySelector('.s-header').classList.remove('navMobileVisible')
      document.querySelector('body').classList.remove('s-overflow--hidden')
    }
  }

}
</script>
